.header {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  isolation: isolate;
}

.main-menu {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.95);
  isolation: isolate;
  width: 100%;
  max-width: 100%;
}

/* Main dropdown styles */
.main-menu-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80px;
  padding: 0 0.5rem;
}

.main-menu-dropdown .flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.main-menu-trigger {
  background: none;
  border: none;
  color: white;
  opacity: 0.8;
  cursor: pointer;
  padding: 0.25rem;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-menu-trigger:hover {
  color: #4a9eff;
  opacity: 1;
}

.main-menu-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 200px;
  background: rgba(0, 0, 0, 0.95);
  border-radius: 4px;
  padding: 0.25rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.3);
}

.menu-label {
  font-size: 12px;
  margin-top: 4px;
  color: white;
  opacity: 0.9;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-family: 'Segoe UI', system-ui, sans-serif;
  background: linear-gradient(45deg, #4a9eff, #a855f7, #ec4899);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 8s ease infinite;
  cursor: pointer;
  white-space: nowrap;
}

/* Dropdown content buttons */
.main-menu-content button {
  background: none;
  border: none;
  color: white;
  font-size: 0.9rem;
  text-align: left;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 2px;
  width: 100%;
}

.main-menu-content button:hover {
  background: rgba(74, 158, 255, 0.1);
}

.main-menu-content button.active {
  background: rgba(74, 158, 255, 0.2);
  color: #4a9eff;
}

.broken-world {
  transform: rotate(-15deg);
  stroke-width: 1.5;
  color: white;
  opacity: 0.8;
}

.broken-world:hover {
  color: #4a9eff;
  opacity: 1;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Mobile Responsive Styles */
@media (max-width: 480px) {
  .main-menu {
    padding: 0.25rem;
  }

  .main-menu-dropdown {
    min-width: 60px;
    padding: 0 0.25rem;
  }

  .menu-label {
    font-size: 10px;
  }

  .main-menu-content {
    min-width: 160px;
  }

  .main-menu-content button {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
}